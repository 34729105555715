export default {
  name: "ListHistoryAngsuran",
  data() {
    return {
      property: {
        listElement: {
          historyAngsuran: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          cifIdName: "",
          loanAccountNumber: "",
        },
      },
      table: {
        data: {
          historyAngsuran: [],
        },
      },
    };
  },
  methods: {
    async searchHistoryAngsuran() {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/history",
          payload: {
            cifIdName: this.property.filterDto.cifIdName,
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            page: 0,
            size: this.property.listElement.historyAngsuran.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.historyAngsuran = resp.data.data.content;
            this.property.listElement.historyAngsuran.currentPage =
              resp.data.data.pageable.pageNumber + 1;
            this.property.listElement.historyAngsuran.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataHisoryAngsuran(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetHistoryAngsuran(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.historyAngsuran.downloading = false;
        }, timeout + 500);
      }
    },
    routeToPageDetailHistoryAngsuran(props) {
      sessionStorage.setItem("LOAN_ACCOUNT_NUMBER", props.row.contractNumber);
      this.$router.push("detail");
    },
    handleErrorGetHistoryAngsuran(error) {
      console.log(error.response);
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.rows = 0;
      this.property.listElement.historyAngsuran.currentPage = 1;
      this.property.listElement.historyAngsuran.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationHistoryAngsuran(event) {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/history",
          payload: {
            cifIdName: this.property.filterDto.cifIdName,
            loanAccountNumber: this.property.filterDto.loanAccountNumber,
            page: event - 1,
            size: this.property.listElement.historyAngsuran.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.historyAngsuran = resp.data.data.content;
            this.property.listElement.historyAngsuran.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataHisoryAngsuran(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetHistoryAngsuran(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.historyAngsuran.downloading = false;
        }, timeout);
      }
    },
    async getHistoryAngsuran() {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "loan",
          reqUrl: "loan-installment/history",
          payload: {
            cifIdName: "",
            loanAccountNumber: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.historyAngsuran = resp.data.data.content;
            this.property.listElement.historyAngsuran.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataHisoryAngsuran(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetHistoryAngsuran(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.historyAngsuran.downloading = false;
        }, timeout);
      }
    },
    failedGetDataHisoryAngsuran(resp) {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.rows = 0;
      this.property.listElement.historyAngsuran.message = resp.data.message;
    },
  },
  mounted() {
    this.getHistoryAngsuran();
  },
};
